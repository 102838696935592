import React, { useEffect, useState } from "react";
import { Button, Row, Col, Tabs } from "antd";
import style from "./style.module.scss";
import BankDepositeTab from "./BankDepositeTab";
import WithdrawTabs from "./WithdrawTabs";
import TabPane from "antd/lib/tabs/TabPane";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import { isLevel3 } from "../../../../helpers";
import Identityverify from "../../../Common/Identityverify";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../../../redux/services";

const BanksDeposite = () => {
  const { t } = useTranslation();
  const { ArrowRightGreen } = StoreImages;
  const { data } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cryptopage = () => {
    // Use navigate to go to the About page
    navigate("/cryoto_deposit");
  };
  const type = location.state?.type || "deposit" || "withdraw";
  // const [selectedPane, setSelectedPane] = useState(
  //   type === "Send" ? "withdraw" : "deposit"
  // );

  const { tab } = useSelector((state) => state.sendReceive);
  console.log(tab, "tabb");
  useEffect(() => {
    dispatch(getProfileData());
  }, []);

  const QA = [
    {
      id: 1,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434834196249--%D0%9A%D1%80%D0%B8%D0%BF%D1%82%D0%BE-%D0%BE%D1%80%D0%BB%D0%BE%D0%B3%D0%BE-%D1%85%D0%B8%D0%B9%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">
            {t("BankDeposit.fq.Q1")}
          </a>
        </>
      ),
      // Ans: <>{t("BankDeposit.fq.ans1")}</>,
    },
    {
      id: 2,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434853562137--%D0%9A%D1%80%D0%B8%D0%BF%D1%82%D0%BE-%D0%B7%D0%B0%D1%80%D0%BB%D0%B0%D0%B3%D0%B0-%D0%B3%D0%B0%D1%80%D0%B3%D0%B0%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">
            {t("BankDeposit.fq.Q2")}
          </a>{" "}
        </>
      ),
      // Ans: <>{t("BankDeposit.fq.ans2")}</>,
    },
    {
      id: 3,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434807953305--%D0%A2%D3%A8%D0%93%D0%A0%D3%A8%D0%93-%D0%B8%D0%B9%D0%BD-%D0%BE%D1%80%D0%BB%D0%BE%D0%B3%D0%BE-%D1%85%D0%B8%D0%B9%D1%85-%D1%85%D1%8D%D1%82%D1%8D%D0%B2%D1%87-%D1%86%D1%8D%D0%BD%D1%8D%D0%B3%D0%BB%D1%8D%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">
            {t("BankDeposit.fq.Q3")}{" "}
          </a>
        </>
      ),
      // Ans: <>{t("BankDeposit.fq.ans3")}</>,
    },
    {
      id: 4,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434803881369--%D0%A2%D3%A8%D0%93%D0%A0%D3%A8%D0%93-%D0%B8%D0%B9%D0%BD-%D0%B7%D0%B0%D1%80%D0%BB%D0%B0%D0%B3%D0%B0-%D0%B3%D0%B0%D1%80%D0%B3%D0%B0%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">
            {t("BankDeposit.fq.Q4")}
          </a>
        </>
      ),
      // Ans: <><a href=""></a></>,
    },
  ];

  // if (!isLevel3(data?.level)) {
  //   return <Identityverify />;
  // }
  console.log(tab, "selectedPane");
  return (
    <div className="comman-padding">
      <div className={style.banner}>
        <div className={style.banner_content}>
          <h4>{t("Fund.subheadingtitle2")}</h4>
          <Button onClick={cryptopage}>
            {t("BankDeposit.btnText")}
            <img style={{ padding: "6px" }} src={ArrowRightGreen} alt="icon" />
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "30px" }} className="dispositWithdrawTab">
        <Row gutter={[40, 40]}>
          <Col lg={12} className="cmnTabStyle">
            <div className="bank-deposite-draw-tabs">
              <Tabs
                className="open_AllOrderTab"
                defaultActiveKey="1"
                // onChange={(tab) => setSelectedPane(value)}
                activeKey={tab}
              >
                <TabPane
                  tab={<p>{t("history_account.deposits")}</p>}
                  key="deposit"
                >
                  {tab === "deposit" && <BankDepositeTab />}
                </TabPane>
                <TabPane
                  tab={<p>{t("history_account.withdraws")}</p>}
                  key="withdraw"
                >
                  {tab === "withdraw" && <WithdrawTabs />}
                </TabPane>
              </Tabs>
            </div>
          </Col>
          <Col lg={12} className="cmnTabStyle">
            <div className={style.QApart}>
              <h4>{t("BankDeposit.fq.title")}</h4>
              {QA.map((text) => (
                <div className={style.QApart_content} key={text.id}>
                  <h5>{text.Question}</h5>
                  <p>{text.Ans}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BanksDeposite;
