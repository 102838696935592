import React, { useCallback, useEffect, useState } from "react";
import { Select, Form } from "antd";
import style from "./style.module.scss";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import Addbank from "./Addbank";
import { ButtonCustom } from "../../../Ui/button/ButtonCustom";
import CommonModal from "../../../CommonModal";
import { useTranslation } from "react-i18next";
import {
  deleteBankDetails,
  fetchBankbeneficiary,
  getBalance,
  getCurrencies,
  getOtpServicePeatio,
  getUser,
  withdrawsPost,
  getProfileData,
  fetchWithdrawLimit,
  fetchWithdrawFees,
} from "../../../../redux/services";
import { useDispatch, useSelector } from "react-redux";
import Custom_input from "../../../Ui/customInput/Custom_input";
import {
  debouncing,
  isLevel3,
  multiple0BeforeDecimal,
  regex,
  truncateData,
  uppercase,
} from "../../../../helpers";
import GoogleAuthModal from "../../../Common/Modals/GoogleAuthModal";
import Identityverify from "../../../Common/Identityverify";
import useCountdownSeconds from "../../../../customHooks/useCountdownSeconds";
import { DeleteFilled } from "@ant-design/icons";
import { Modal } from "antd/lib";
import { convertToThousandDigit } from "../../../../helpers/thousand_digit";

const initData = {
  amount: "",
  bankAccount: "",
  otp: "",
};

const WithdrawTabs = () => {
  // Redux setup
  const dispatch = useDispatch();
  const { MNT } = StoreImages;
  const { t } = useTranslation();

  // Redux selectors
  const { bankbeneficiaryList } = useSelector((state) => state?.sendReceive);
  const { data } = useSelector((state) => state.user);
  const { currenciesList, balanceList } = useSelector(
    (state) => state.commonApiData
  );
  const { withdrawLimit, withdrawfee } = useSelector(
    (state) => state.sendReceive
  );
  // Component state
  const [receivedAmount, setReceivedAmount] = useState("0.0");
  const [openModal, setOpenModal] = useState(false);
  const [withdrawData, setWithdrawData] = useState({ ...initData });
  const [errors, setErrors] = useState({ ...initData });
  const [otpClicked, setOtpClicked] = useState(0);
  const [seconds, secondsFun, clearTimer] = useCountdownSeconds(60);
  const [resendStatus, setResendStatus] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState({
    modalStatus: false,
    data: [],
  });

  let is2Fa = data.otp;
  let userBls = balanceList.length > 0 ? balanceList[0]?.balance : 0.0;

  useEffect(() => {
    dispatch(fetchBankbeneficiary());
    dispatch(getCurrencies({ id: "mnt" }));
    dispatch(getBalance({ search: { currency_code: "mnt" } }));
    dispatch(getUser());
    dispatch(fetchWithdrawLimit({ currency_id: "mnt" }));
    // dispatch(getProfileData());
    // return dispatch(getCurrencies({type: "trading"}));
  }, []);
  useEffect(() => {
    if (!seconds) {
      setResendStatus(false);
    }
  }, [seconds]);

  useEffect(() => {
    return () => {
      secondsFun(0);
    };
  }, []);
  useEffect(() => {
    if (Object.keys(currenciesList).length) {
      dispatch(
        fetchWithdrawFees({
          uuid: data.uid,
          currency_id: currenciesList?.id,
        })
      );
    }
  }, [currenciesList]);
  const sendOtp = async () => {
    setResendStatus(true);
    clearTimer();

    let res = await dispatch(getOtpServicePeatio({ otpClicked }));
    if (res.payload.status) {
      secondsFun();
      // State update after secondsFun responds
      setTimeout(() => {
        setOtpClicked(otpClicked + 1);
      }, 1000);
    } else {
      setResendStatus(false);
    }
  };
  const handleDeleteConfirmation = async () => {
    const updateResult = await dispatch(
      deleteBankDetails({ id: deletePopupVisible.data, action: "delete" })
    );

    if (updateResult.payload.status) {
      dispatch(fetchBankbeneficiary());
      setDeletePopupVisible({
        ...deletePopupVisible,
        modalStatus: false,
        data: [],
      });
    }
  };
  const bankNameDropdown =
    bankbeneficiaryList?.length > 0 &&
    bankbeneficiaryList?.map((item) => {
      return {
        value: item.id,
        label: (
          <div className="bankAccountSelect">
            {item.bank_name && item.bank_name === "khan"
              ? " Khan Bank"
              : item.bank_name}
            <span className="remove_account">{item.account_number}</span>
            {/* <DeleteFilled
              className="remove_account"
              onClick={() => {
                setDeletePopupVisible({
                  ...deletePopupVisible,
                  data: item.id,
                  modalStatus: true,
                });
              }}
            /> */}
          </div>
        ),
      };
    });
  const onChange = (value) => {
    setWithdrawData({ ...withdrawData, bankAccount: value });
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  let minWithdrawAmt = currenciesList?.swap_min_amount;
  const handleValidation = (data) => {
    let isValidated = true;
    let copyerror = { ...errors };
    for (let key in data) {
      let value = data[key]?.toString().trim();
      switch (key) {
        case "amount":
          // setGetAmount(0);
          let floatedval = parseFloat(value);
          // let willGetBal = parseFloat(Number(value)) - parseFloat(Number(withdraw_fee));
          // let minWithdrawAmt = plusNum(min_withdraw_amount, withdraw_fee);

          // let truncateGetAmount = truncateData(
          //   willGetBal,
          //   currentCurrency?.precision
          // );

          // let finalGetAmount =
          //   Number(truncateGetAmount) < 0 ? 0 : truncateGetAmount;
          if (value === "") {
            copyerror[key] = "Please enter a valid amount";
            isValidated = false;
          } else if (floatedval > userBls) {
            copyerror[key] =
              "Enter an amount not higher than available balance";
            isValidated = false;
          } else if (floatedval < minWithdrawAmt) {
            copyerror[
              key
            ] = `Amount to send should be atleast ${minWithdrawAmt}`;
            isValidated = false;
          } else if (floatedval < withdrawLimit?.min_withdraw_limit) {
            copyerror[
              key
            ] = `Amount to send should be atleast ${withdrawLimit?.min_withdraw_limit}`;
            isValidated = false;
          } else if (
            floatedval > truncateData(withdrawLimit?.fiat_withdraw_limit, 2)
          ) {
            copyerror[key] = `Amount to send should be less than ${truncateData(
              withdrawLimit?.fiat_withdraw_limit,
              2
            )}`;
            isValidated = false;
          } else {
            copyerror[key] = "";
            // setGetAmount(finalGetAmount);
          }
          break;
        case "bankAccount":
          if (value === "") {
            copyerror[key] = "Please choose bank Account";
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;
        case "otp":
          if (value === "") {
            copyerror[key] = "Please enter code";
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;

        default:
      }
    }
    setErrors({ ...copyerror });
    return isValidated;
  };

  const onSubmitFun = async (e) => {
    e.preventDefault();
    let res = handleValidation(withdrawData);
    if (!is2Fa && otpClicked === 0) {
      setErrors({ ...initData, otp: t(`withdraw.getOtpError`) });
      return;
    }
    if (res) {
      let amount = withdrawData.amount;
      if (amount.endsWith(".")) {
        amount = amount + "0";
      }
      const sendData = {
        bank_detail_id: withdrawData.bankAccount,
        amount: amount,
        currency: "mnt",
        // blockchain_key: blockchain_key,
      };
      if (is2Fa) {
        sendData["otp"] = withdrawData.otp;
      } else {
        sendData["code"] = withdrawData.otp;
      }
      let data = await dispatch(withdrawsPost(sendData));

      if (data.payload.status) {
        dispatch(getBalance());
        setWithdrawData({ ...initData });
        clearTimer();
        setErrors({ ...initData });
        setOtpClicked(0);
        setResendStatus(false);
        setReceivedAmount("0.0");
      }
    }
  };

  // const resetFields = () => {
  //   setWithdrawData({ ...initData });
  //   setErrors({ ...initData });
  // };
  const finalWithdraw = (e) => {
    onSubmitFun(e);

    // !is2Fa ? setOpenModal(true) : onSubmitFun(e);
  };
  let finalfee =
    withdrawfee[0]?.fiat_fee_type === "percentage"
      ? Number(withdrawData?.amount) * Number(withdrawfee[0]?.fiat_fee)
      : withdrawfee[0]?.fiat_fee;

  const delayToCallApi = debouncing((entered_val) => {
    const val = Number(entered_val);

    if (val > 0 && withdrawfee[0]?.fiat_fee) {
      setReceivedAmount(val - finalfee);
    } else {
      setReceivedAmount(0.0);
    }
  }, 1000);

  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;
    const amountRegex = regex(6, 10);
    const addessRegex = /^[A-Za-z0-9]*$/;
    const optRegex = /^[0-9]*$/;

    const re =
      name === "amount"
        ? amountRegex
        : name === "address"
        ? addessRegex
        : optRegex;

    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
      delayToCallApi(entered_val);
    }

    if (entered_val === "" || re.test(entered_val)) {
      handleValidation({ [name]: entered_val });
      setWithdrawData({ ...withdrawData, [name]: entered_val });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  if (!isLevel3(data?.level)) {
    return <Identityverify />;
  }
  return (
    <div className={`${style.withdrawPage} withdrawPage`}>
      <div className={style.bar}>
        <h4>
          <span>{t("BankDeposit.maintabs.Currency")}</span>
          <img src={MNT} alt="icon" />
          MNT
        </h4>
      </div>
      <div className={style.DepositContent}>
        <Form.Item
          label={t("BankDeposit.maintabs.ChooseBankAccountlabel")}
          name=""
        >
          <Select
            label={t("BankDeposit.withdrawal_amount")}
            className="customSelect"
            showSearch
            value={withdrawData.bankAccount || undefined}
            placeholder="Choose Bank Account"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={bankNameDropdown}
          />

          {errors.bankAccount && (
            <span className={`errorCls ${style.errorText}`}>
              {errors.bankAccount}
            </span>
          )}
        </Form.Item>
        <Addbank />

        <div className={style.amountWithcurncy}>
          <Custom_input
            label={t("BankDeposit.withdrawal_amount")}
            placeholder={t("BankDeposit.enter_amount")}
            name="amount"
            value={withdrawData.amount}
            onChange={handleChange}
            max={100}
            height={"50px"}
            customClass={"customInputWithdarw"}
          />
          <span className={style.crncyName}>
            {uppercase(currenciesList?.id)}
          </span>
        </div>
        {errors.amount && (
          <span className={`errorCls ${style.errorText}`}>{errors.amount}</span>
        )}
        <div className={style.withdrawInfo}>
          <p className={style.avlBls}>
            {t("portfolio.available_balance")} :
            <b style={{ display: "inline-block", maxWidth: "150px" }}>
              {" "}
              {convertToThousandDigit(userBls)}
              <span> {uppercase(currenciesList?.id)}</span>{" "}
            </b>
          </p>
          <div className={style.withdrawInfo_innerDiv}>
            <p style={{ display: "flex", whiteSpace: "nowrap" }}>
              {t("withdraw.fee")} :{" "}
              <b style={{ display: "inline-block", maxWidth: "150px" }}>
                {withdrawfee?.length > 0 && (
                  <>
                    {withdrawfee[0]?.fiat_fee_type === "percentage" ? (
                      <>
                        {Number(withdrawData?.amount) *
                          Number(withdrawfee[0]?.fiat_fee)}
                      </>
                    ) : (
                      <>{withdrawfee[0]?.fiat_fee}</>
                    )}{" "}
                  </>
                )}

                {/*
              {withdrawLimit?.fiat_withdraw_fee_percent
                ? truncateData(
                    (withdrawData?.amount *
                      withdrawLimit?.fiat_withdraw_fee_percent) /
                      100,
                    2
                  )
                : "0"}{" "} */}
                {uppercase(currenciesList?.id)}
              </b>
            </p>
            <p style={{ display: "flex", whiteSpace: "nowrap" }}>
              {t("withdraw.receive_amount")} :
              <b style={{ display: "inline-block", maxWidth: "150px" }}>
                {withdrawfee?.length > 0 && (
                  <>
                    {withdrawfee[0]?.fiat_fee_type === "percentage" ? (
                      <>
                        {Number(withdrawData?.amount) -
                          Number(withdrawData?.amount) *
                            Number(withdrawfee[0]?.fiat_fee)}
                      </>
                    ) : (
                      <>
                        {Number(withdrawData?.amount) -
                          Number(withdrawfee[0]?.fiat_fee)}
                      </>
                    )}{" "}
                  </>
                )}

                {/* {isNaN(receivedAmount) ? "0.0" : receivedAmount}{" "}
              {uppercase(currenciesList?.id)} */}
              </b>
            </p>
          </div>
          <div className={style.withdrawInfo_innerDiv}>
            <p style={{ display: "flex", whiteSpace: "nowrap" }}>
              {t("withdraw.least")} :
              <b style={{ display: "inline-block", maxWidth: "150px" }}>
                {withdrawLimit?.min_withdraw_limit
                  ? truncateData(withdrawLimit?.min_withdraw_limit, 2)
                  : "0.0"}{" "}
                {uppercase(currenciesList?.id)}
              </b>
            </p>
            <p style={{ display: "flex", whiteSpace: "nowrap" }}>
              {t("withdraw.Daily_limit")} :
              <b style={{ display: "inline-block", maxWidth: "150px" }}>
                {withdrawLimit?.fiat_withdraw_limit
                  ? truncateData(withdrawLimit?.fiat_withdraw_limit, 2)
                  : "0.0"}{" "}
                {uppercase(currenciesList?.id)}
              </b>
            </p>
          </div>
        </div>

        <div
          className={`${style.withdrawalAddress_inputSec} ${style.lastChild}`}
        >
          {is2Fa ? (
            <>
              <Custom_input
                label={t("login.enter_the_digit")}
                placeholder={t("withdraw_fiat.enter_Code")}
                name="otp"
                value={withdrawData.otp}
                onChange={handleChange}
                max={6}
                height={"50px"}
                customClass={"customInputWithdarw"}
              />
            </>
          ) : (
            <div
              className={`${style.input_righttext} input_righttext passPadding`}
            >
              <Custom_input
                name="otp"
                value={withdrawData.otp}
                placeholder={t("withdraw_fiat.enter_Code")}
                onChange={handleChange}
                onlycurrency={`${
                  otpClicked
                    ? seconds
                      ? `${seconds} Seconds`
                      : `Resend OTP`
                    : `${t("addrsManagement.getCode")}`
                }`}
                rightTextClass={resendStatus && !seconds ? "otp_clicked" : ""}
                {...(!resendStatus && {
                  rightTextClick: () => {
                    sendOtp();
                  },
                })}
              />

              {/* <CustomError errors={errors} name="otp" /> */}
            </div>
          )}
          <span className={`errorCls ${style.bottomO} ${style.errorTextInput}`}>
            {errors.otp}
          </span>
        </div>

        {/* <CommonModal>dflkjljfkslkjf</CommonModal> */}

        <ButtonCustom
          // label={is2Fa ? t("BankDeposit.maintabs.Wbtn") : t("withdraw.enable")}
          label={t("BankDeposit.maintabs.Wbtn")}
          greenbtn
          onClick={(e) => finalWithdraw(e)}
          disabled={withdrawData?.amount < withdrawLimit?.min_withdraw_limit}
        />
      </div>
      {openModal && (
        <GoogleAuthModal
          open={openModal}
          onCancel={() => setOpenModal(false)}
        />
      )}
      {deletePopupVisible.modalStatus && (
        <Modal
          className="customModalStyle"
          title={t("deleteBank.dltBankAc")}
          open={deletePopupVisible.modalStatus}
          onOk={handleDeleteConfirmation}
          onCancel={() =>
            setDeletePopupVisible({
              ...deletePopupVisible,
              modalStatus: false,
              data: [],
            })
          }
        >
          <p>{t("deleteBank.dltBankAcNote")}</p>
        </Modal>
      )}
    </div>
  );
};

export default WithdrawTabs;
