import React, { useState } from "react";
import { Button, Modal } from "antd";
import CommonRenderFields from "../../../Ui/commonRenderFields";
import {
  addBankObj,
  addBankObjDefault,
  bankList,
  fieldType,
} from "../../../../staticObjects";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addBankSchema } from "../../../../Schema";
import CommonButton from "../../../Ui/button/CommonButton";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import { addBankbeneficiary } from "../../../../redux/services";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { shallowEqual, useSelector } from 'react-redux'

const Addbank = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { normalInput, selectInput } = fieldType;
  const { chooseAccount, accountNo, accountName } = addBankObj;
  const { userData } = useSelector((state) => state?.setting);
  const { data } = useSelector((state) => state.user)

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let profileType = userData?.account_type;

  let nameOfUser =
    profileType === "corporate" ?
      userData?.organization_details?.length > 0 ?
        userData?.organization_details?.filter(item => item.another_organization_details === false)[0]?.name :
        userData?.organization_details?.name
      : userData?.first_name + " " + userData?.last_name

  const {
    handleSubmit,
    control,
    formState: { errors, isValid: formIsvalid },
    getValues,
    clearErrors,
    resetField,
    reset,
  } = useForm({
    resolver: yupResolver(addBankSchema),
    defaultValues: {
      chooseAccount: "",
      accountNo: "",
      accountName: nameOfUser
    }, // Set default value for piNationality
    mode: "onChange",
  });
  const onSubmit = async (items) => {
    const { chooseAccount, accountNo, accountName } = items;
    let lastName =
      data?.profiles?.length > 0 && data?.profiles[0]?.last_name !== null
        ? data?.profiles[0]?.last_name
        : "";
    const sendData = {
      bank_name: chooseAccount,
      bank_account_holder_name: accountName,
      account_number: accountNo,
    };

    let res = await dispatch(addBankbeneficiary(sendData));
    if (res?.payload?.status) {
      setIsModalOpen(false);
      reset();
    }

  };
  const commanProp = { errors, control };


  return (
    <>
      <div className={style.withdrawBtn}>
        <Button
          style={{ color: "#00A79E", textAlign: "right" }}
          type="link"
          onClick={showModal}
        >
          {t("BankDeposit.addBankmodal.AddBankAccount")}
        </Button>
      </div>
      <Modal
        className={`${style.addBeneficiary}  headerModals buySellModa`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <div className={style.DepositContent}>
          <h5
            style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}
          >
            {t("BankDeposit.addBankmodal.AddBankAccount")} 
          </h5>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={style.beneficiaryForm}
          >
            <CommonRenderFields
              {...commanProp}
              type={selectInput}
              name={chooseAccount}
              // defaultValue="А" 
              label={t("BankDeposit.addBankmodal.ChooseBankAccount")}
              placeholder="Choose Bank Account"
              dropOption={bankList}
            />

            <CommonRenderFields
              {...commanProp}
              name={accountNo}
              // placeholder={"123456987"}
              label= {t("BankDeposit.addBankmodal.BankNumber")}
              type={normalInput}
            />
            <CommonRenderFields
              control={control}
              {...commanProp}
              readOnly
              name={accountName}
              // placeholder={"Customer Name"}
              label={t("BankDeposit.addBankmodal.BankName")}
              type={normalInput}
            />
            <CommonButton btn_custom title={t("BankDeposit.addBankmodal.Verify")} htmlType="submit" />
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Addbank;
