import React, { useEffect } from "react";
import "../style.scss";
function TermsConditions() {
  useEffect(() => {
    document.body.classList.add("policyPages");
    return () => {
      document.body.classList.add("policyPages");
    };
  }, []);
  return (
    <div>
      <div class="page">
        <div class="header">
          <div class="container">
            <p class="title">“COINHUB” ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</p>
          </div>
        </div>
        <div class="translations-content-container">
          <div class="container">
            <div
              class="tab-content translations-content-item en visible"
              id="en"
            >
              <p className="textRight mb-15">
                Сүүлд өөрчлөлт оруулсан: 2022 оны 10-р сарын 13-ны өдөр
              </p>
              <p className="mb-15">
                Харилцагч Та (цаашид “та” эсхүл “харилцагч” гэх) КОЙН ХАБ ХХК
                (&quot;Бирж” эсхүл “COINHUB” гэх)-аас www.coinhub.mn вэбсайт
                эсвэл гар утасны аппликейшн (хамтад нь “платформ” гэх)-аар
                дамжуулан санал болгож буй виртуал хөрөнгийн үйлчилгээ (цаашид
                “”COINHUB” Үйлчилгээ” гэх)-г авахаас өмнө виртуал хөрөнгө, түүнд
                хамаарах үйлчилгээний дараах эрсдэлүүдийг мэдэж, хүлээн
                зөвшөөрөх шаардлагатай. Үүнд:
              </p>

              <ol className="">
                <li>
                  <p>Аль нэг улсын хууль ёсны мөнгөн тэмдэгтэд хамаарахгүй;</p>
                </li>
                <li>
                  <p>
                    Аль нэг улсын Засгийн газар эсвэл Төв банкнаас
                    баталгаажихгүй;
                  </p>
                </li>
                <li>
                  <p>Зээл, үнэт цаас, бонд биш;</p>
                </li>
                <li>
                  <p>
                    Аливаа хуулийн этгээдийн эзэмшил, удирдлагад хэрэгжүүлэх
                    аливаа эрх, тэдгээрийг хэрэгжүүлэх эсвэл үүрэг үүсгэх ямар
                    нэг эрх биш;
                  </p>
                </li>
                <li>
                  <p>
                    Гүйлгээ нь буцаах боломжгүй, залилан мэхлэх шинжтэй, эсхүл
                    алдаатай гүйлгээнээс үүдэн гарах үр дагавар, нөхөн
                    төлөгдөхгүй;
                  </p>
                </li>
                <li>
                  <p>
                    Гүйлгээ хийгдсэн хугацаанаас үл хамааран бүртгэлийн системд
                    бүртгэгдсэн хугацаагаар гүйлгээ хийгдсэнд тооцогддог;
                  </p>
                </li>
                <li>
                  <p>
                    Виртуал хөрөнгийн талаарх олон улсын хэм хэмжээ, дотоодын
                    хууль тогтоомжоос хамаарч виртуал хөрөнгийн үйлчилгээ болон
                    үнэ цэнэ огцом өөрчлөгдөх /буурах, өсөх/;
                  </p>
                </li>
                <li>
                  <p>
                    Залилан мэхлэх болон цахим халдлагад өртөх, алдаатай болон
                    гэмт хэргийн шинжтэй гүйлгээнээс үүсэх үр дагаварт бий болох
                    хохирол нөхөн төлөгдөхгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    Зах зээлд оролцогчдын эрэлт хэрэгцээнээс хамаарч үнэ цэний
                    хэлбэлзлийн үр дагавраар алдагдал хүлээх;
                  </p>
                </li>
                <li>
                  <p>
                    Хадгаламжийн даатгал, үнэт цаасны хөрөнгө оруулагчдыг
                    хамгаалах аливаа даатгалд хамаардаггүй;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээнд технологийн хүндрэл гарсан тохиолдолд
                    харилцагч өөрийн виртуал хөрөнгийг ашиглах, хэрэглэх
                    боломжгүй болох;
                  </p>
                </li>
                <li>
                  <p>
                    Биржийн өмчилж, эсхүл эзэмшиж байгаа хөрөнгө нь харилцагчид
                    учирсан хохирлыг бүрэн барагдуулж хүрэлцэхгүй байх;
                  </p>
                </li>
              </ol>
              <p className="mb-15">
                Харилцагч ““COINHUB” ХАРИЛЦАГЧИЙН ГЭРЭЭ”-г баталгаажуулах үед
                “COINHUB Үйлчилгээний нөхцөл (“Үйлчилгээний нөхцөл”)-ийг хүлээн
                зөвшөөрч, ойлгож, танилцсан байна.
              </p>
              <p className="mb-15">
                Үйлчилгээний нөхцөлөөр Биржийн “COINHUB” Үйлчилгээнд холбогдох
                нөхцөлүүдийг тодорхойлох ба Та “COINHUB” Үйлчилгээг ашиглах үед
                мөрдөгдөх тул анхааралтай уншиж танилцана уу.
              </p>
              <h2 className="mb-15"> НЭР ТОМЬЁОНЫ ТОДОРХОЙЛОЛТ</h2>
              <ol>
                <li>
                  <p className="mb-15">
                    Үйлчилгээний нөхцөлд хэрэглэсэн дараах нэр томьёог дор
                    дурдсан агуулгаар ойлгоно. Үүнд:
                  </p>
                </li>
                <ol>
                  <li>
                    <p className="mb-15">
                      <b>“COINHUB”</b> гэж “COINHUB”-ын вэбсайт (домайн нэр
                      буюу coinhub.mn), “COINHUB”-аас хөгжүүлж, харилцагчид
                      виртуал хөрөнгийн үйлчилгээг санал болгож буй гар утасны
                      аппликейшн, платформуудыг;
                    </p>
                    <li>
                      <p>
                        <b> “COINHUB” Үйлчилгээ </b>гэж www.coinhub.mn вэбсайт,
                        гар утасны аппликейшн болон бусад хэлбэрээр виртуал
                        хөрөнгийн Арилжаа, шилжүүлэг, хадгалалт, удирдлага, олон
                        нийтэд санал болгох, худалдах хүрээнд “COINHUB”-аас
                        санал болгож буй виртуал хөрөнгийн үйлчилгээг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“CHB” гэж Биржийн гаргасан виртуал хөрөнгийг;</b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“Арилжаа”</b> гэж “COINHUB” дээр виртуал хөрөнгө,
                        фиат хооронд, нэг виртуал хөрөнгийг нөгөө виртуал
                        хөрөнгөөр арилжихыг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“Виртуал Хөрөнгө”</b> гэж аливаа улсын албан ёсны
                        мөнгөн тэмдэгт, үнэт цаасны дижитал хэлбэр, эсхүл
                        Монголбанкны зөвшөөрөлтэй цахим мөнгөнөөс бусад
                        дижиталаар 2 шилжүүлэх, арилжаалах боломжтой төлбөрийн,
                        эсхүл хөрөнгө оруулалтын зорилгоор ашиглагдах үнэ цэний
                        дижитал илэрхийлэл бүхий эдийн бус хөрөнгийг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          “Виртуал хөрөнгө гаргагч” гэж IEO хийх замаар Биржийн
                          харилцагч нагт виртуал хөрөнгийг худалдаж, хөрөнгө
                          татан төвлөрүүлж буй компанийг;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“Фиат”</b> гэж аливаа улсын албан ёсны мөнгөн
                        тэмдэгтийг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          &quot;МУТС” гэж мөнгө угаах, терроризмыг санхүүжүүлэх;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b> “Өндөр эрсдэлтэй харилцагч” гэж </b>улс төрд нөлөө
                        бүхий этгээд, МУТС-тэй тэмцэх чиг үүрэг бүхий олон улсын
                        байгууллагаас МУТС-тэй тэмцэх хяналтын хангалтгүй
                        тогтолцоотой гэж зарлагдсан улсын хүн, хуулийн этгээд,
                        МУТС-тай тэмцэх үндэсний эрсдэлийн үнэлгээгээр өндөр
                        эрсдэлтэй гэж үнэлэгдсэн салбарт үйл ажиллагаа явуулж
                        байгаа хүн, хуулийн этгээдийг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“Улс төрд нөлөө бүхий этгээд” гэж </b> Нийтийн албанд
                        нийтийн болон хувийн ашиг сонирхлыг зохицуулах, ашиг
                        сонирхлын зөрчлөөс урьдчилан сэргийлэх тухай хуулийн
                        20.2-т заасан этгээд, эсхүл гадаад улсын түүнтэй
                        адилтгах албан тушаалтан болон холбогдох эрх бүхий
                        байгууллагаас баталсан бусад журам, заавар заасан албан
                        тушаалтныг ойлгоно;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>&quot;Үйлчилгээний нөхцөл” гэж </b>“COINHUB”-аас
                        харилцагч нарт одоо болон ирээдүйд санал болгож буй
                        тухайлсан виртуал хөрөнгийн үйлчилгээний мэдээлэл,
                        шалгуур, харилцагч үйлчилгээг ашиглахад мөрдөх журам
                        зэрэг үндсэн болон бусад нөхцөлийг тусгасан нийтэд
                        нээлттэй байршуулсан цахим баримт бичгийг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b> “Харилцагч”</b> гэж Гэрээ болон Бүртгэлийн шалгуур,
                        Харилцагчид тавигдах шаардлагыг хангаж, танин
                        баталгаажуулалт хийсэн, тухайлсан виртуал хөрөнгийн
                        Үйлчилгээний нөхцөлийг хангаж буй хувь хүн, ашгийн төлөө
                        хуулийн этгээдийг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“Хэтэвч”</b> гэж COINHUB Үйлчилгээг авах үед фиат,
                        виртуал хөрөнгийг удирдахад Харилцагчид зориулан
                        “COINHUB” дээр виртуал байдлаар үүсгэж, хадгалж буй
                        дансыг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>“Шилжүүлэг” гэж </b>Биржийн харилцагч нарын хэтэвч
                        хооронд, Бирж болон гуравдагч виртуал хөрөнгийн
                        үйлчилгээ үзүүлэгч эсхүл хэтэвчний үйлчилгээ үзүүлэгчийн
                        хэтэвч хооронд гүйцэтгэгдэж буй виртуал хөрөнгийн
                        гүйлгээг;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          “Initial Exchange Offer” (&quot;IEO”) гэж Биржээс
                          хамтын ажиллагааны гэрээ байгуулсны үндсэн дээр
                          Виртуал хөрөнгө гаргагчийн виртуал хөрөнгийг Биржийн
                          харилцагч нарт санал болгон худалдан замаар хөрөнгө
                          татан төвлөрүүлэх ажиллагааг;
                        </b>
                      </p>
                    </li>
                  </li>
                </ol>
              </ol>
              <p className="mb-15">
                Та “COINHUB” Үйлчилгээнд нэвтрэх, ашиглахаар оролдлого хийхээс
                эхлээд бүхий л тохиолдолд Үйлчилгээний Нөхцөлийг мөрдөх бөгөөд
                хэрэв Та зөвшөөрөхгүй байгаа бол даруй үйлдлээ зогсооно уу.
              </p>
              <h2 className="mb-15">ЕРӨНХИЙ НӨХЦӨЛ</h2>
              <ol>
                <li>
                  <p>
                    Биржийн болон виртуал хөрөнгийн үйлчилгээний хурдтай
                    хөгжлөөс шалтгаалан Та болон Биржийн хоорондын харилцааг
                    зохицуулах эдгээр Үйлчилгээний Нөхцөл нь талуудын бүхий л
                    эрх, үүргийг тусгах боломжгүй бөгөөд ирээдүйн хөгжилтэй
                    холбоотой үүссэн аливаа шаардлагад бүрэн нийцэх баталгаа
                    болохгүй.
                  </p>
                </li>
                <li>
                  <p className="mb-15">
                    Бирж нь Таныг “COINHUB” Үйлчилгээг авч байх хугацаанд өөрийн
                    үйлчилгээг өргөтгөх, шинээр үйлчилгээ нэмж, тэдгээрт
                    хамаарах үйлчилгээний нөхцөлүүдийг шинэчлэх бөгөөд хамгийн
                    сүүлд өөрчилсөн огноог харуулна. Үйлчилгээний нөхцөлийг
                    шинэчилсэн тухай мэдэгдлийг дараах хэлбэрээр Танд мэдэгдэнэ.
                    Үүнд:
                  </p>
                  <ol>
                    <li>
                      <p>
                        Таныг Биржийн платформд нэвтэрч үйлчилгээ авах үед
                        автоматаар “Мэдэгдэл” нэртэй цонх гарч ирэх бөгөөд тус
                        цонхонд шинэчилсэн Үйлчилгээний нөхцөлтэй танилцах
                        холбоосыг оруулна;
                      </p>
                    </li>
                    <li>
                      <p>
                        Таны Биржид бүртгэлтэй и-мэйл хаягт “Мэдэгдэл”-ийг
                        шинэчилсэн Үйлчилгээний Нөхцөлийн холбоосын хамт
                        илгээнэ;
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний нөхцөлд оруулсан аливаа өөрчлөлт нь Биржийн
                        цахим хуудсанд нийтлэгдсэн үеэс эхлэн хүчин төгөлдөр
                        үйлчилнэ.
                      </p>
                    </li>
                    <li>
                      <p>
                        Та дээрх хэлбэрүүдээр Танд ирүүлсэн болон цахим хуудсанд
                        гарч ирсэн “Мэдэгдэл” дэх холбоосоор орж шинэчилсэн
                        Үйлчилгээний Нөхцөлтэй танилцах үүрэгтэй тул тухай бүр
                        танилцаж байхыг Танд зөвлөж байна.
                      </p>
                    </li>
                    <li>
                      <p>
                        Хэрэв Та шинэчлэгдсэн Үйлчилгээний нөхцөлийг мөрдөх
                        боломжгүй гэж үзвэл Та “COINHUB” Үйлчилгээг авахаа
                        зогсооно уу.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний нөхцөл шинэчлэгдсэний дараа ч Та
                        “COINHUB”-ын Үйлчилгээг үргэлжлүүлэн авч байгаа
                        тохиолдолд Таныг Үйлчилгээний Нөхцөлийг хүлээн
                        зөвшөөрсөн гэж үзнэ.
                      </p>
                    </li>
                    <li>
                      <p>
                        “COINHUB” Үйлчилгээнд нэвтэрч, хэрэглэж буй нь Та
                        өөрийгөө “COINHUB” үйлчилгээг хэсэгчлэн болон бүхэлд нь
                        хориглосон улсын иргэн эсвэл оршин суугч биш гэдгийг
                        баталгаажуулж байгаа болно. Бирж нь өөрийн “COINHUB”
                        Үйлчилгээг тухайлсан нутаг дэвсгэр болон бүс нутгийн зах
                        зээлийг сонгож явуулах эрхтэй болно.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
              <h2 className="mb-15">COINHUB ҮЙЛЧИЛГЭЭ</h2>
              <ol>
                <li>
                  <p>
                    <b>Арилжаа</b>
                  </p>
                  <ol>
                    <li>
                      <p className="mb-15">
                        Арилжаа нь дараах төрлүүдтэй байна. Үүнд:
                      </p>
                      <ol>
                        <li>
                          <p>
                            Харилцагч өөрийн Биржийн хэтэвч дэх мөнгөн хөрөнгөөр
                            виртуал хөрөнгө худалдан авах
                          </p>
                        </li>
                        <li>
                          <p>Виртуал хөрөнгийг өөр виртуал хөрөнгөөр солих;</p>
                        </li>
                        <li>
                          <p>
                            Виртуал хөрөнгийг мөнгөн хөрөнгө болгон худалдах;
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p className="mb-15">
                        Биржээс харилцагчид арилжааны захиалга хийх дараах
                        аргуудыг санал болгоно. Үүнд:
                      </p>
                      <ol>
                        <li>
                          <p>
                            Тухайлсан хэмжээ, ханш тогтоож өгөх захиалга (limit
                            orders);
                          </p>
                        </li>
                        <li>
                          <p>
                            Захиалгын бүртгэл дэх хамгийн боломжит ханшаар
                            биелэх, хэмжээ тогтоосон захиалга (market orders);
                          </p>
                        </li>
                      </ol>
                      <li>
                        <p>
                          Харилцагч гадаадын эсхүл дотоодын ангилал дахь виртуал
                          хөрөнгөөс сонгож, Биржийн “Арилжаа” хэсгээс ханшийн
                          мэдээллийг харж захиалга өгөх боломжтой.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагч Бирж дээрх зөвхөн өөрийн Хэтэвчийг ашиглан
                          арилжааны захиалга хийх боломжтой бөгөөд харилцагчийн
                          сонгосон захиалгын аргаас хамаарч Биржийн систем
                          захиалгыг тухай бүр гүйцэтгэнэ.
                        </p>
                      </li>
                      <li>
                        <p>
                          Авах, зарах захиалга нь зөвхөн харилцагчийн үйлдэлд
                          үндэслэх бөгөөд захиалга биелсэн тухай бүр
                          харилцагчийн хэтэвч дэх мөнгөн хөрөнгө эсхүл виртуал
                          хөрөнгийн хэмжээнд өөрчлөлт орно.
                        </p>
                      </li>
                      <li>
                        <p>
                          Бирж нь зөвхөн өөрийн системд бүртгэлтэй харилцагчийн
                          хэтэвч дэх мөнгөн хөрөнгө, виртуал хөрөнгийн арилжааны
                          түүхийг бүртгэх бөгөөд харилцагч хэдийд ч харах,
                          танилцах боломжтой байна. Тухайлсан арилжааны түүх нь
                          захиалгын төрөл, захиалга өгсөн виртуал хөрөнгө бүхий
                          хослол, түүний ханш, хэмжээ, захиалга биелсэн эсхүл
                          цуцлагдсан хугацаа зэрэг бусад мэдээллийг агуулна.
                        </p>
                      </li>
                      <li>
                        <p>
                          Тухайлсан хэмжээ, ханш тогтоосон авах, зарах захиалгын
                          хувьд зарах, авах захиалга хэсэгчлэн эсхүл бүхэлдээ
                          тохирохгүй тохиолдолд хугацаа тогтоогоогүй бол
                          харилцагч тус захиалгыг цуцлах хүртэл эсхүл бусад хэд
                          хэдэн харилцагчийн захиалгатай тохирох хүртэл
                          хугацаанд захиалгын бүртгэлд нээлттэй байршина.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагчийн хэтэвч дэх мөнгөн хөрөнгө, виртуал
                          хөрөнгө нь захиалга хийхэд хүрэлцэхгүй тохиолдолд
                          тухай бүр бичгээр /дэлгэцэн дээр/ зохих анхааруулга
                          өгөх бөгөөд боломжит захиалгын хэмжээг харилцагчид
                          санал болгох тохиргоог Бирж хийж болно.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагчийнплатформд хийх нийт зарлагын шилжүүлгийн
                          дээд хэмжээ Бирж тогтооно.
                        </p>
                      </li>
                      <li>
                        <p>
                          Биржээс тухайлсан виртуал хөрөнгийн болон үйлчилгээг
                          таниулах, арилжааны хэмжээг нэмэгдүүлэх зорилгоор
                          харилцагч нарын дунд зарласан урамшуулалт арга хэмжээ,
                          хөтөлбөр, идэвхжүүлэлтийн ажлын хүрээнд хийгдэх
                          арилжааны захиалгад баримтлах дүрмийг тусгайлан
                          тогтоож болно. Тус дүрмийг Биржийн цахим хуудсанд
                          нийтэд нээлтэйгээр байршуулж, харилцагчдад танилцуулах
                          ба харилцагч Арилжаанд оролцохдоо тус дүрмийг
                          баримтална.
                        </p>
                      </li>
                      <li>
                        <p>
                          Бирж арилжаанаас хасах шийдвэр гаргасан виртуал
                          хөрөнгийн хувьд Биржийн мэдэгдэлд дурдсан хүрээнд
                          арилжааг гүйцэтгэх боломжтой.
                        </p>
                      </li>
                      <li>
                        <p>
                          Биржийн техникийн сайжруулалт, шинэчлэлт, засвар
                          үйлчилгээ, тухайлсан виртуал хөрөнгийн олон улсын зах
                          зээл дэх ханшийн хэт өсөлт болон хэт уналт, виртуал
                          хөрөнгийн болон сүлжээний форк, кибер халдлага,
                          аюулгүй байдлыг хангах үйл ажиллагааны явцад
                          харилцагчийн арилжааны захиалга авахгүй байх, захиалга
                          цуцлагдах эрсдэлийг Биржийн Үйлчилгээний нөхцөлд
                          тухайлан заана. Энэ төрлийн эрсдэл учирч болзошгүйг
                          Бирж өөрийн нийт харилцагч нарт урьдчилан мэдэгдэх
                          эсхүл боломжгүй тохиолдолд нөхцөл байдал илэрсэн даруй
                          мэдэгдэх арга хэмжээг шуурхай авна.
                        </p>
                      </li>
                      <li>
                        <p>
                          Бирж дээр арилжаалах виртуал хөрөнгийн жагсаалт нь
                          олон улсын болон дотоодын зах зээлд арилжаалагдаж буй
                          виртуал хөрөнгө болон Биржээр дамжуулан нийтэд санал
                          болгох ажиллагаа амжилттай болж бүртгэгдсэн виртуал
                          хөрөнгүүдээс бүрдэнэ.
                        </p>
                      </li>
                      <li>
                        <p>
                          Биржийн Арилжааны жагсаалт дах виртуал хөрөнгийн үнэ
                          ханш нь олон улсын зах зээл дээрх дундаж үнээс
                          харьцангуй доогуур, эсхүл дээгүүр байх тохиолдолд
                          үнийн зөрүүг тогтворжуулан хөрвөх чадварыг нэмэгдүүлэх
                          зорилгоор арилжаа хийх тохиолдолд энэ талаар харилцагч
                          нарт Биржийн цахим хуудсаар дамжуулан урьдчилж
                          мэдээлнэ.
                        </p>
                      </li>
                      <li>
                        <p>
                          Виртуал хөрөнгө гаргагч нь Биржийн Арилжааны жагсаалт
                          дахь өөрийн гаргасан виртуал хөрөнгийн үнэ ханшийг
                          огцом унахаас сэргийлэх зорилгоор Биржтэй тохиролцож,
                          холбогдох гэрээ байгуулсан тохиолдолд түүний үндсэн
                          дээр өөрөө эргүүлэн худалдан авах ажиллагааг хийх
                          тохиолдолд энэ талаар харилцагч нарт Биржийн цахим
                          хуудсаар дамжуулан урьдчилж мэдээлнэ.
                        </p>
                      </li>
                    </li>
                  </ol>
                  <li>
                    <p className="mb-15">
                      <b>Шилжүүлэг/Зарлага</b>
                    </p>
                    <ol>
                      <li>
                        <p>
                          Харилцагч өөрийн Хэтэвчнээс зарлага хийх дээд
                          хэмжээнээс хэтрүүлэхгүйгээр фиатаар эсхүл виртуал
                          хөрөнгөөр хэдэн ч удаа зарлага хийх боломжтой.
                        </p>
                      </li>
                      <li>
                        <p>
                          Хэтэвчнээс хийх виртуал хөрөнгийн шилжүүлэг нь Биржийн
                          өөр нэг хэтэвчинд, Бирж болон гуравдагч виртуал
                          хөрөнгийн үйлчилгээ үзүүлэгч эсхүл хэтэвчний үйлчилгээ
                          үзүүлэгч хооронд гүйцэтгэгдэнэ.
                        </p>
                      </li>
                      <li>
                        <p>
                          Бирж дээрээс хийгдэх харилцагчийн аливаа виртуал
                          хөрөнгийн шилжүүлгийг харилцагчаар 2 шатлалт
                          баталгаажуулалт хийлгэсний үндсэн дээр гүйцэтгэнэ.
                          Баталгаажуулалт нь харилцагч системд бүртгэлтэй өөрийн
                          нууц үгийг оруулсны дараа түүний бүртгэлтэй утасны
                          дугаарт Биржээс илгээсэн дахин давтагдахгүй
                          баталгаажуулалтын кодыг илгээж, тус кодыг оруулснаар
                          бүрэн хангагдана.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагч виртуал хөрөнгийн шилжүүлэг хийх үед байнга
                          нэвтэрдэггүй төхөөрөмж, байршлаас нэвтэрсэн, нэвтрэх
                          нэр, нууц үгийн мэдээллийг 2-оос дээш удаа буруу
                          оруулсан тохиолдолд Биржээс харилцагчийн бүртгэлтэй
                          и-мэйл эсхүл утасны дугаар руу баталгаажуулах код
                          бүхий мэдэгдэл илгээж харилцагчийг танин
                          баталгаажуулсны дараа шилжүүлгийг гүйцэтгэнэ.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагч Биржээс гадагш буюу гуравдагч виртуал
                          хөрөнгийн үйлчилгээ үзүүлэгч эсхүл хэтэвчний үйлчилгээ
                          үзүүлэгчийн данс руу 3,000,000 төгрөг болон түүнээс
                          дээш дүнтэй шилжүүлэг хийхээс өмнө харилцагчаас дараах
                          мэдээллийг гаргуулж авна.
                        </p>
                        <ol>
                          <li>
                            <p>
                              Хүлээн авагч хувь хүний овог, нэр/ хуулийн
                              этгээдийн нэр;
                            </p>
                          </li>
                          <li>
                            <p>Хүлээн авагчийн бүртгэлтэй хаяг;</p>
                          </li>
                          <li>
                            <p>Шилжүүлгийн зорилго;</p>
                          </li>
                          <li>
                            <p>Хүлээн авагч талын оршин суугаа улсын нэр;</p>
                          </li>
                          <li>
                            <p>
                              Хүлээн авагч талын шилжүүлгийг гүйцэтгэж буй
                              виртуал хөрөнгийн үйлчилгээ үзүүлэгчийн нэр;
                            </p>
                          </li>
                        </ol>
                        <li>
                          <p>
                            Харилцагчийн шилжүүлэг хийх мэдээлэл бүрэн бус эсхүл
                            алдаатай тохиолдолд мэдээллийг бүрэн, үнэн зөв болох
                            хүртэл хугацаанд Бирж шилжүүлгийг түдгэлзүүлнэ.
                          </p>
                        </li>
                        <li>
                          <p>
                            Бирж дэх харилцагчийн данснаас аливаа гуравдагч
                            виртуал хөрөнгийн үйлчилгээ үзүүлэгч эсхүл хэтэвчний
                            үйлчилгээ үзүүлэгч дэх дансанд виртуал хөрөнгийг
                            шилжүүлэх эсхүл тэдгээрээс хүлээн авах аль ч
                            тохиолдолд шилжүүлгийг зуучилж буй тухайн виртуал
                            хөрөнгийн үйлчилгээ үзүүлэгч эсхүл хэтэвчний
                            үйлчилгээ үзүүлэгчийг тогтоож, харилцагчийг таньж
                            баталгаажуулах, аюулгүй байдлыг хангах арга хэмжээ
                            авсны дараа шилжүүлгийг гүйцэтгэнэ.
                          </p>
                        </li>
                        <li>
                          <p>
                            Компани Бирж дэх виртуал хөрөнгийн шилжүүлгийн үйл
                            ажиллагаанд шилжүүлгийн мэдээллийг дэлгэрэнгүй
                            нөхүүлж авах ажиллагааг 2023 оны 07 дугаар сарын
                            01-ний өдрийг хүртэл хугацаанд зохих хэмжээнд
                            хэрэгжүүлэх бөгөөд бүрэн хэрэгжүүлэх бэлтгэл ажлыг
                            хангаж ажиллана.
                          </p>
                        </li>
                        <li>
                          <p>
                            Аливаа алдаатай гүйлгээ хийгдсэн тохиолдолд тухайн
                            алдаатай гүйлгээг харилцагч үл маргах журмаар
                            буцаана.
                          </p>
                        </li>
                      </li>
                    </ol>
                  </li>
                </li>
                <li>
                  <p className="mb-15">
                    <b>Staking</b>
                  </p>
                  <ol>
                    <li>
                      <p>
                        Биржээс тухайлсан виртуал хөрөнгийг тодорхой хугацаа,
                        хэмжээний шаардлагыг хангасан тохиолдолд өгөөжтэй
                        хадгалах нөхцөлийг харилцагчид санал болгох үйлчилгээ
                        бөгөөд тус үйлчилгээг түр зогсоох, идэвхжүүлэх бүрэн
                        эрхийг Бирж дангаар эдэлнэ.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний өгөөжийн хэмжээг зах зээлийн нөхцөл байдал,
                        эрсдэлийн хүчин зүйлсийг харгалзан Бирж дангаар тогтоох
                        эрхтэй ба тухай бүр www.coinhub.mn цахим хуудсаар
                        үйлчилгээг авахад тооцооллыг автоматаар гүйцэтгэж,
                        өгөөжийн хувийг харилцагчид харуулна.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний өгөөжийн хэмжээнд өрсөлдөөн эсхүл ижил
                        төсөөтэй үйлчилгээ, банкны хадгаламжийн хүүгийн хэмжээ
                        зэрэг бусад аливаа төрлийн үйлчилгээ хамааралгүй байна.
                      </p>
                    </li>
                    <li>
                      <p>
                        Харилцагч CHB Staking үйлчилгээ авч буй тохиолдолд
                        үйлчилгээнд ашиглаж буй CHB-д ногдол орлого
                        хуваарилагдах боломж хэвээр хадгалагдах ба өгөөж
                        байдлаар хүлээн авсан CHB-н хувьд харилцагчийн хэтэвчинд
                        орсон дараагийн өдрөөс ногдол орлого хуваарилах
                        боломжтой.
                      </p>
                    </li>
                    <li>
                      <p>
                        Харилцагч үйлчилгээг хэзээд цуцлах эрхтэй бөгөөд
                        үйлчилгээний хугацаа дуусах үед үйлчилгээг авах эрх
                        дахин нээгдэнэ.
                      </p>
                    </li>
                    <li>
                      <p>
                        Харилцагч үйлчилгээний хугацаа дуусах эсвэл өөрийн
                        хүсэлтээр үйлчилгээг цуцлах хүртэл үйлчилгээ авахад
                        ашигласан виртуал хөрөнгийг түгжих тул бусдад шилжүүлэх,
                        арилжаалах боломжгүй.
                      </p>
                    </li>
                    <li>
                      <p>
                        Харилцагч хугацаанаас өмнө үйлчилгээг цуцлах тохиолдолд
                        үйлчилгээний нөхцөлд тодорхойлсон өгөөжийг хүртэх
                        боломжгүй.
                      </p>
                    </li>
                    <li>
                      <p>
                        Харилцагч үйлчилгээг цуцлах хүсэлт өгснөөс хойш 48
                        цагийн дотор Бирж хүсэлтийг шийдвэрлэх бөгөөд энэ
                        хугацаанд үйлчилгээнд ашигласан виртуал хөрөнгийг бусдад
                        шилжүүлэх, арилжаалах боломжгүй байна.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний хугацаа дуусмагц өгөөж харилцагчийн
                        хэтэвчинд автоматаар шилжиж орно.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээнд урьдчилан таамаглах боломжгүй эрсдэлүүд
                        дараах хүчин зүйлсээс эсхүл эдгээрээр хязгаарлагдахгүй
                        бусад хүчин зүйлсээс хамаарч учирч болно. Үүнд:
                      </p>
                      <ol>
                        <li>
                          <p>
                            Бирж нь хуулиар зохицуулагдсан банк, хадгаламжийн
                            болон үнэт цаасны үйлчилгээг үзүүлэгч биш;
                          </p>
                        </li>
                        <li>
                          <p>
                            Үйлчилгээнд ашиглаж буй виртуал хөрөнгө нь аливаа
                            даатгалгүй;
                          </p>
                        </li>
                        <li>
                          <p>
                            Өгөөж нь ямар нэг баталгаа эсхүл эрх биш бөгөөд Бирж
                            өгөөжийн хэмжээг хэдийд ч өөрчлөх боломжтой
                            /үйлчилгээ авах үед тогтоосон өгөөж энэхүү заалтад
                            хамаарахгүй/;
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <li>
                    <p className="mb-15">
                      <b>
                        Виртуал хөрөнгийг олон нийтэд санал болгох буюу Initial
                        Exchange Offering /Цаашид IEO/
                      </b>
                    </p>
                    <ol>
                      <li>
                        <p>
                          Харилцагч Биржийн платформд нэвтрэн, IEO хэсэгт дарж,
                          Бирж дээр зарлагдсан IEO-д оролцох боломжтой.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагч IEO-д оролцохоос өмнө Биржид байршуулсан
                          тухайн виртуал хөрөнгийн анхдагч арилжааны захиалга
                          авах хугацаа, Бирж дээр анхдагч арилжааг нээх хугацаа,
                          виртуал хөрөнгийн нэрлэсэн нэгж үнэ, анхдагч
                          арилжаагаар нийт захиалга авах тоо ба татан
                          төвлөрүүлэх мөнгөний хэмжээ, Бирж дээр захиалга авах
                          тоо ба татан төвлөрүүлэх мөнгөний хэмжээ, нэг
                          харилцагч IEO-д оролцох дээд болон доод хязгаарлалтын
                          мэдээлэл, виртуал хөрөнгийн танилцуулгын холбоосоор
                          мэдээлэлтэй танилцаж үйлчилгээг авна.
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагч IEO-д оролцохдоо захиалгын тоо, хэмжээг
                          нягтлах, анхаарал болгоомжтой байх бөгөөд захиалгыг
                          буцаахгүй тул буруу захиалга өгсөнтэй холбоотой үр
                          дагаврыг харилцагч зөвхөн өөрөө хариуцах ба Бирж
                          аливаа хариуцлага хүлээхгүй.
                        </p>
                      </li>
                      <li>
                        <p>
                          Бирж дээрх IEO-д оролцох эсэх нь зөвхөн харилцагчийн
                          өөрийн сонголт байх тул Та үйлчилгээг авах эсэхийг
                          сонголтыг сайтар эргэцүүлсний үндсэн дээр
                          шийдвэрлэхийг сануулж байна.
                        </p>
                      </li>
                      <li>
                        <p>
                          Бирж виртуал хөрөнгийн IEO-г хийсэн нь тухайн виртуал
                          хөрөнгөд аливаа байдлаар баталгаа өгч буй хэлбэрийг
                          биш бөгөөд Арилжааны явцад тухайн виртуал хөрөнгийн
                          ханшийн өөрчлөлтөд Бирж аливаа хариуцлага хүлээхгүй.
                        </p>
                      </li>
                    </ol>
                    <li>
                      <p>
                        “COINHUB” Үйлчилгээг Харилцагчийн гэрээг цахимаар
                        байгуулж, тус Гэрээнд заасан бүртгэлийн баталгаажуулалт
                        хийсэн харилцагч ашиглах боломжтой.
                      </p>
                    </li>
                    <li>
                      <p>
                        Бирж “COINHUB” Үйлчилгээгээр дамжиж буй мэдээллийг
                        хадгалах боловч тэдгээрийн үнэн зөв байдал, хэрэглээ,
                        холбоотой байдал, хэрэгцээт байдалд баталгаа өгөхгүй
                        бөгөөд тэдгээр контентын агуулгаас шууд болон шууд
                        бусаар учирсан аливаа хохирлыг хариуцахгүй.
                      </p>
                    </li>
                    <li>
                      <p>
                        “COINHUB” Үйлчилгээний талаарх мэдээлэл нь Харилцагчийг
                        хараат бусаар шийдвэр гаргахад нь туслалцаа үзүүлэхэд
                        чиглэх бөгөөд аливаа мэдэгдэл өгөлгүйгээр өөрчлөгдөх
                        боломжтой.
                      </p>
                    </li>
                    <li>
                      <p>
                        “COINHUB” нь хөрөнгө оруулалтын болон зөвлөх
                        үйлчилгээний зөвлөгөө өгөхгүй, зуучлагчийн аливаа
                        харилцаа гэж үзэхгүй бөгөөд “COINHUB” дээрх мэдээллийг
                        ашиглах болон тайлбарлахад ямар нэг хариуцлага
                        хүлээхгүй. “COINHUB”-ын бүх Харилцагч нь Виртуал
                        Хөрөнгийн арилжаанд оролцох эрсдэлийг ойлгосон байх нь
                        өөрийнхөө төлөө хийж шаардлагатай бөгөөд хариуцлагатай
                        үйлдэл бөгөөд хянамгай байх, арилжааны үр дагавар,
                        хариуцлагыг өөрийн хөрөнгөөр хүлээнэ гэдгээ хүлээн
                        зөвшөөрч, энэ талаар судалж, мэдсэн байхыг зөвлөж байна.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний нөхцөлд хэрэгжүүлэхээр заасан аливаа заалт
                        хэрэгжээгүй, хойшлогдсон нь байгалийн гамшигт үзэгдэл,
                        цахилгаан, эрчим хүчний уналт, тасалдал, төрийн
                        байгууллагын шийдвэр, иргэдийн бослого хөдөлгөөн,
                        террорист халдлага, гал түймэр, цар тахал зэрэг
                        эдгээрээр хязгаарлагдахгүй Биржийн боломжит хяналтаас
                        гадуурх аливаа үйлдэл, эс үйлдэхүй, хүчин зүйлсийн үр
                        дагаварт хамаарах бол Бирж аливаа хариуцлага хүлээхгүй.
                      </p>
                    </li>
                    <li>
                      <p>
                        Үйлчилгээний нөхцөлийн аль нэг хэсэг нь хүчин төгөлдөр
                        бус болохыг эрх бүхий байгууллагаас тогтоосон нь
                        Үйлчилгээний нөхцөлийг бүхэлд нь болон бусад хэсгийг
                        хүчингүй болгох үндэслэл болохгүй бөгөөд тэдгээр нь
                        үйлчилсэн хэвээр байна.
                      </p>
                    </li>
                    <li>
                      <p>
                        “COINHUB” Үйлчилгээний талаарх илүү дэлгэрэнгүй
                        мэдээллийг Та Биржийн албан ёсны цахим хуудас
                        www.coinhub.mn сайтаас авна уу. Үйлчилгээний нөхцөлтэй
                        холбоотой тодруулах зүйлс байвал Харилцагчийн төвийн
                        callcenter@coinhub.mn хандаж мэдээлэл авах боломжтой.
                      </p>
                    </li>
                  </li>
                </li>
              </ol>
              <h2 className="mb-15">ҮНДЭСНИЙ ХУУЛЬ ТОГТООМЖИД НИЙЦҮҮЛЭХ</h2>
              <ol>
                <li>
                  <p>
                    Харилцагч нь Монгол Улсын болон өөрийн оршин суугаа газрын
                    үндэсний хууль тогтоомжийг дагаж мөрдөх үүрэгтэй бөгөөд
                    тэдгээр хууль тогтоомжоор тодорхойлсон татварын асуудлыг
                    татварын эрх бүхий байгууллагад уламжилж бие даан шийдвэрлэх
                    үүрэгтэй.
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагч “COINHUB” Үйлчилгээнд ашиглаж буй фиат, виртуал
                    хөрөнгөө хууль ёсны эх үүсвэртэй болохыг баталгаажуулах
                    үүрэгтэй бөгөөд ашиглаж буй фиат, виртуал хөрөнгө нь аливаа
                    хууль бус эх үүсвэртэй гэж эрх бүхий бүхий байгууллагаас
                    шалгах тохиолдолд холбоотой мэдээлэл, материалыг шуурхай
                    гарган өгөх үүрэгтэй.
                  </p>
                </li>
                <li>
                  <p>
                    Бирж нь олон улсын хууль хэрэгжүүлэх эрх бүхий
                    байгууллагуудтай нээлттэй хамтран ажиллах тул хуулийн дагуу
                    шалгаж тогтоосон тохиолдолд Харилцагчийн хэтэвчийг царцаах,
                    хаах арга хэмжээг шууд авна.
                  </p>
                </li>
              </ol>
              <h2 className="mb-15">МЭДЭЭЛЭЛ ДАМЖУУЛАХ</h2>
              <ol>
                <li>
                  <p>
                    ФАТФ-ын “Криптовалютын гүйлгээний дүрэм”, “ВХҮҮ-ийн үйл
                    ажиллагааны журам”-ын 3.2-р заалтын дагуу ВХҮҮ нь
                    Үйлчилгээний нөхцлийн 3.2.5-д заасан харилцагчийн гүйлгээтэй
                    холбоотой шилжүүлэгчийн болон хүлээн авагчийн мэдээллийг
                    гуравдагч этгээдэд хүргүүлнэ. Энэ нь таны мэдээлэл биржийн
                    үйл ажиллагаатай холбоотой гуравдагч этгээдэд дамжуулагдах
                    магадлалтайг анхаарна уу. Бирж нь таны мэдээллийг зөвхөн
                    Олон улсын стандарт, МУ-н хууль, журмын хүрээнд хууль ёсны
                    дагуу ашиглана.
                  </p>
                </li>
              </ol>
              <h2 className="mb-15">НУУЦЛАЛ</h2>
              <ol>
                <li>
                  <p>
                    Харилцагч “COINHUB” Үйлчилгээг авах үед өөрийн хувийн
                    мэдээллийг тодорхой хүрээнд өгөх шаардлагатай бөгөөд
                    Харилцагчийн хувийн мэдээллийг баталгаажуулалтад ашиглах,
                    цуглуулахтай холбоотой харилцааг нэгтгэн Нууцлалын бодлогоор
                    зохицуулсан тул тус бодлоготой танилцана уу.
                  </p>
                </li>
                <li>
                  <p>
                    ФАТФ-ын “Криптовалютын гүйлгээний дүрэм”, “ВХҮҮ-ийн үйл
                    ажиллагааны журам”-ын 3.2-р заалтын дагуу ВХҮҮ нь
                    Үйлчилгээний нөхцлийн 3.2.5-д заасан харилцагчийн гүйлгээтэй
                    холбоотой шилжүүлэгчийн болон хүлээн авагчийн мэдээллийг
                    гуравдагч этгээдэд хүргүүлнэ. Энэ нь таны мэдээлэл биржийн
                    үйл ажиллагаатай холбоотой гуравдагч этгээдэд дамжуулагдах
                    магадлалтайг анхаарна уу. Бирж нь таны мэдээллийг зөвхөн
                    Олон улсын стандарт, МУ-н хууль, журмын хүрээнд хууль ёсны
                    дагуу ашиглана.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
