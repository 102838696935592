import React, { useEffect, useRef, useState } from "react";
import { Button, Input, message, Modal, Row, Tabs } from "antd";
import { Trans, useTranslation } from "react-i18next";
import CommonButton from "../../Ui/button/CommonButton";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import CommonAssetNetworkList from "./commonAssetNetworkList";
import {
  depositWithdrawDropdownType,
  fieldType,
  initPhone,
  sendReceiveType,
} from "../../../staticObjects";
import {
  concatePhoneNo,
  convertNetworkName,
  customPhoneValidation,
  isLevel3,
  multiple0BeforeDecimal,
  regex,
  toOptionalFixed,
  truncateData,
  uppercase,
} from "../../../helpers";
import style from "./style.module.scss";
import { plusNum } from "../../../utils/formatter";
import { useDispatch, useSelector } from "react-redux";
import Custom_input from "../../Ui/customInput/Custom_input";
import AddressBook from "./addressBookModal";
import {
  createWithdraw,
  fetchIsInternalAddress,
  getOtpServicePeatio,
} from "../../../redux/services";
import GoogleAuthModal from "../../Common/Modals/GoogleAuthModal";
import { FocusWithIncreaseWidth } from "../../FocusAndIncreaseWidth";
import Identityverify from "../../Common/Identityverify";
import TabPane from "antd/lib/tabs/TabPane";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAccountSchema } from "../../../Schema";
import { useForm } from "react-hook-form";
import CustomPhoneNoInput from "../../Ui/PhoneNoInput";
import { emailRegex } from "../../../Schema/untils";
import useCountdownSeconds from "../../../customHooks/useCountdownSeconds";
import { useLocation, useParams } from "react-router-dom";
import { convertToThousandDigit } from "../../../helpers/thousand_digit";
import check from "../../Assets/Images/check.png";
import Roundedimg from "../../Assets/Images/rounded-info-button.png";
import { debounce } from "lodash";
import {
  setCoinHubUser,
  setNetworkError,
  setSelectedPaneTab,
  setText,
} from "../../../redux/feature";
const { normalInput } = fieldType;

const initData = {
  amount: 0,
  address: "",
  otp: "",
  email: "",
  twoFaOtp: "",
  code: "",
  network: "",
};
const Send = (props) => {
  const { t } = useTranslation();
  const currencyId = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);
  const { networkErr, coinHubUser, tab } = useSelector(
    (state) => state.sendReceive
  );
  const internal = useSelector(
    (state) => state.sendReceive.isItInternalAddress
  );
  const { beneficiaryList } = useSelector((state) => state.sendReceive);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setSelectedPaneTab("deposit"));
  //   };
  // }, []);

  const [cmnModalState, setCmnModalState] = useState({
    isWithOpen: false,
    isModalOpen: false,
    isSuccOpen: false,
    sendData: null,
  });

  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [balanceObjState, setBalanceObjState] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const [currentCurrency, setCurrentCurrency] = useState({});
  const [withdrawData, setWithdrawData] = useState({ ...initData });
  const [errors, setErrors] = useState({ ...initData });
  const [getAmount, setGetAmount] = useState(0.0);
  const [disbaleBtn, setDisableBtn] = useState(false);
  const [enable2fa, setEnable2fa] = useState(false);
  const [selectedPane2, setSelectedPane2] = useState("email");
  const [addressBookModal, setAddressBookModal] = useState(false);
  const [phone, setPhone] = useState({ ...initPhone });
  const [firstClick, setSirstClick] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [otpClicked, setOtpClicked] = useState(0);
  const [seconds, secondsFun, clearTimer, setSeconds] = useCountdownSeconds(60);
  const [resendStatus, setResendStatus] = useState(false);
  const [protocol, setProtocol] = useState("");
  const [states, setStates] = useState(false);
  const [checkNetworkErr, setCheckNetworkErr] = useState(false);
  const [isInternalAddress, setIsInternalAddress] = useState(false);

  const amountRef = useRef();
  const { balanceObj, closeModal } = props;
  const { exchange, AddressIcon } = StoreImages;
  const { asset, network } = depositWithdrawDropdownType;
  const { currency_name, currency, balance } = balanceObjState;
  const { min_withdraw_amount, withdraw_fee, blockchain_key, internal_fee } =
    currentNetwork;
  const currInCap = currency && uppercase(currency);
  const is2FA = data.otp;
  // useEffect(() => {
  //   if (!networkErr) {
  //     setCurrentNetwork({});
  //   }
  // }, [networkErr]);

  useEffect(() => {
    if (firstClick) {
      const res = customPhoneValidation(phone);
      setPhoneError(res.error);
    }
  }, [firstClick]);

  useEffect(() => {
    if (!seconds) {
      setResendStatus(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (Object.keys(currentNetwork).length > 0) {
      setErrors({
        ...errors,
        network: "",
      });
      setWithdrawData({
        ...withdrawData,
        network: "",
      });
    }
  }, [currentNetwork]);
  useEffect(() => {
    const calculateGetAmount = () => {
      let floatedval = parseFloat(withdrawData.amount);
      let withdrawFee =
        isInternalAddress === false ? withdraw_fee : internal_fee;
      let willGetBal = floatedval - parseFloat(withdrawFee);
      let minWithdrawAmt = plusNum(min_withdraw_amount, withdrawFee);
      let truncateGetAmount = truncateData(
        willGetBal,
        currentCurrency?.precision
      );
      return Number(truncateGetAmount) < 0 ? 0 : truncateGetAmount;
    };
    setGetAmount(calculateGetAmount());
  }, [withdrawData.amount, currentNetwork]);

  const debouncedFetchInternalAddress = useRef(
    debounce((address) => {
      dispatch(fetchIsInternalAddress(address));
    }, 1000)
  ).current;

  useEffect(() => {
    if (withdrawData.address !== "") {
      debouncedFetchInternalAddress(withdrawData.address);
    }
  }, [withdrawData.address, debouncedFetchInternalAddress]);
  // useEffect(() => {
  //   if (withdrawData.address !== "") {
  //     dispatch(fetchIsInternalAddress(withdrawData.address));
  //   }
  // }, [withdrawData.address]);

  useEffect(() => {
    setIsInternalAddress(internal);
    setGetAmount(0);
    withdrawData.amount = 0;
  }, [internal]);

  const sendOtp = async () => {
    setResendStatus(true);
    clearTimer();
    let res = await dispatch(getOtpServicePeatio({ otpClicked }));
    if (res.payload.status) {
      secondsFun();
      // State update after secondsFun responds
      setTimeout(() => {
        setOtpClicked(otpClicked + 1);
      }, 1000);
    } else {
      setResendStatus(false);
    }
  };

  const filterSelectCurrObj = (value) => {
    return currenciesList?.filter((item) => item.id === value)[0];
  };

  const { control } = useForm({
    resolver: yupResolver(createAccountSchema),
  });
  const handleValidation = (data) => {
    let isValidated = true;
    let copyerror = { ...errors };
    for (let key in data) {
      let value = data[key].toString().trim();

      switch (key) {
        case "amount":
          setGetAmount(0);
          let floatedval = parseFloat(value);
          let withdrawFee =
            isInternalAddress === false ? withdraw_fee : internal_fee;
          let willGetBal = floatedval - parseFloat(withdrawFee);
          let minWithdrawAmt = plusNum(min_withdraw_amount, withdrawFee);

          let truncateGetAmount = truncateData(
            willGetBal,
            currentCurrency?.precision
          );
          let finalGetAmount =
            Number(truncateGetAmount) < 0 ? 0 : truncateGetAmount;

          if (value === "") {
            copyerror[key] = "Please enter a valid amount";
            isValidated = false;
          } else if (floatedval > balance) {
            copyerror[key] =
              "Enter an amount not higher than available balance";
            isValidated = false;
          } else if (floatedval < minWithdrawAmt) {
            copyerror[
              key
            ] = `Amount to send should be at least ${minWithdrawAmt}`;
            isValidated = false;
          } else {
            copyerror[key] = "";
            setGetAmount(finalGetAmount);
          }
          break;

        case "address":
          if (coinHubUser === "withdrawalAddress") {
            if (value === "") {
              copyerror[key] = t(`withdraw.addressError`);
              isValidated = false;
            } else {
              copyerror[key] = "";
            }
          }
          break;

        case "email":
          if (
            coinHubUser !== "withdrawalAddress" &&
            selectedPane2 === "email"
          ) {
            if (value.trim() === "") {
              copyerror[key] = t(`withdraw.emailError`);
              isValidated = false;
            } else if (!emailRegex.test(value)) {
              copyerror[key] = "Please enter a valid Email";
              isValidated = false;
            } else {
              copyerror[key] = ""; // Clear any existing error message
            }
          }
          break;

        case "code":
          if (value === "") {
            if (otpClicked === 0) {
              copyerror[key] = t(`withdraw.getOtpError`);
              isValidated = false;
            } else {
              copyerror[key] = "Please enter code";
              isValidated = false;
            }
          } else {
            copyerror[key] = "";
          }
          break;

        case "otp":
          if (value === "") {
            copyerror[key] = t(`withdraw.2faError`);
            isValidated = false;
          } else if (value === "" && !is2FA) {
            copyerror[key] = "";
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;

        case "network":
          if (
            !currentNetwork?.blockchain_key?.split("-")[0] &&
            coinHubUser === "withdrawalAddress"
          ) {
            dispatch(setNetworkError(true));
            copyerror[key] = t(`withdraw.networkError`);
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;

        default:
          break;
      }
    }

    setErrors({ ...copyerror });
    return isValidated;
  };
  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;
    // Define your regex patterns
    const amountRegex = regex(6, 10);
    const addressRegex = /^[A-Za-z0-9]*$/;
    const otpRegex = /^[0-9]*$/;
    setErrors({
      ...initData,
    });
    // Determine which regex pattern to use based on the input name
    const re =
      name === "amount"
        ? amountRegex
        : name === "address"
        ? addressRegex
        : otpRegex;
    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
    }
    if (entered_val === "" || (re.test(entered_val) && name !== "email")) {
      handleValidation({ [name]: entered_val }); // Perform validation
      setWithdrawData({ ...withdrawData, [name]: entered_val }); // Update state
    } else if (name === "email") {
      handleValidation({ [name]: entered_val }); // Perform validation
      setWithdrawData({ ...withdrawData, [name]: entered_val });
    }
    if (entered_val === "address" && coinHubUser === "withdrawalAddress") {
      setWithdrawData({ ...withdrawData, address: "" });
    }
  };
  useEffect(() => {
    setBalanceObjState(balanceObj);
    setCurrentCurrency({ ...filterSelectCurrObj(balanceObj.currency) });
  }, [balanceObj]);

  const resetFields = () => { 
    setWithdrawData({ ...initData });
    setErrors({ ...initData });
    setGetAmount(0);
    setSirstClick(false);
    setPhone({ ...initPhone });
    setProtocol("");
    // secondsFun(0);
    // setOtpClicked(0);
    // setResendStatus(false);
    // clearTimer();
  };
  const cbFun = (obj, type) => {
    if (type === asset) {
      if (obj.currency !== balanceObjState.currency) {
        setBalanceObjState({ ...obj });
        setCurrentCurrency({ ...filterSelectCurrObj(obj.currency) });
        setCurrentNetwork({});
        // resetFields();
      }
    } else if (type === network) {
      if (obj?.blockchain_key !== currentNetwork.blockchain_key) {
        setCurrentNetwork({ ...obj });
        if (!withdrawData.address) {
          // resetFields();
        }
      }
    }
  };

  useEffect(() => {
    if (states) {
      setWithdrawData({
        ...withdrawData,
        address: "",
      });
    }
  }, [states]);
  // last Modal
  const updateModalState = (updates) => {
    setCmnModalState({ ...cmnModalState, ...updates });
  };

  // finalSubmit
  // const succModal = async () => {
  //   updateModalState({ isWithOpen: false, isSuccOpen: true ,...updates  });
  // };
  const finalSubmit = async () => {
    setDisableBtn(true);
    let data = await dispatch(createWithdraw(cmnModalState.sendData));

    if (data.payload.status) {
      setDisableBtn(false);
      setCmnModalState({
        ...cmnModalState,
        isSuccOpen: true,
        isWithOpen: false,
        isModalOpen: false,
      });
      // updateModalState({ isSuccOpen: false });
    } else {
      resetFields();
      dispatch(setText(true));
      setCmnModalState({
        ...cmnModalState,
        isWithOpen: false,
      });
    }
  };

  const handleCancelsuc = () => {
    updateModalState({ isSuccOpen: false });
    resetFields();
  };

  // 2nd Modal
  const withdrwaModal = () => {
    updateModalState({
      isWithOpen: true,
      isModalOpen: false,
      sendData: cmnModalState.sendData,
    });
  };

  const handleCancel = () => {
    updateModalState({
      isWithOpen: false,
      isModalOpen: false,
    });
  };

  const hndmdlCancel = () => {
    // updateModalState({ isModalOpen: false });
    updateModalState({ isModalOpen: false, isWithOpen: false });
  };
  let phoneNo;
  let phoneValid;

  if (coinHubUser !== "withdrawalAddress" && selectedPane2 === "phone") {
    phoneValid = customPhoneValidation(phone);
    phoneNo = concatePhoneNo(phone);
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setSirstClick(true);
    if (currentNetwork?.blockchain_key?.split("-")[0]) {
      // setCheckNetworkErr(true);
      setWithdrawData({
        ...withdrawData,
        network: false,
      });
    }
    let res = handleValidation(withdrawData);

    if (phoneValid !== undefined && !phoneValid.isValid) return;

    if (res) {
      let amount = withdrawData.amount;
      if (amount.endsWith(".")) {
        amount = amount + "0";
      }

      // setDisableBtn(true);
      const sendData = {
        amount: amount,
        currency: currency,
        blockchain_key: blockchain_key,
      };

      // if (is2FA) {
      //   sendData["otp"] = withdrawData.otp;
      // } else {
      sendData["code"] = withdrawData.code;
      // sendData["phoneOtp"] = withdrawData.phoneOtp;
      sendData["otp"] = withdrawData.otp;

      // }

      if (withdrawData.address !== "") {
        sendData["rid"] = withdrawData.address;
      }

      if (withdrawData.email !== "") {
        sendData["email"] = withdrawData.email;
      }

      if (phoneNo !== "") {
        sendData["phone_number"] = phoneNo;
      }
      sendData["getAmount"] = getAmount;
      updateModalState({ isModalOpen: true, sendData: sendData });
      // let data = await dispatch(createWithdraw(sendData));
      // if (data.payload.status) {
      //   resetFields();
      // }
      // setDisableBtn(false);
    }
  };

  const closeModalIfOnSameRoute = () => {
    setAddressBookModal(false);
    closeModal();
  };

  if (!isLevel3(data?.level)) {
    return <Identityverify cbOnClick={closeModal} />;
  }

  const commanProp = { errors, control };
  const filter = beneficiaryList?.filter(
    (items) =>
      items?.blockchain_key.split("-")[0] ===
      currentNetwork?.blockchain_key?.split("-")[0]
  );

  return (
    <>
      <FocusWithIncreaseWidth ref={amountRef} />
      <div className={style.Buy}>
        <form onSubmit={onSubmit}>
          <Row className={style.Buy_row}>
            <div className="inputSec ">
              {/* <sup>$</sup> */}
              <Input
                name="amount"
                value={withdrawData.amount}
                onChange={handleChange}
                ref={amountRef}
                className="amountField"
              />
            </div>
            <p>
              <span>
                <img src={exchange} alt="icon" />
              </span>
              <br />
              {currInCap}
            </p>
            <span className={`errorCls ${style.errorText}`}>
              {errors.amount}
            </span>
          </Row>

          <p className={`text ${style.Buy_text}`}>
            {t("document_list.avlailableBlc")}:{" "}
            {toOptionalFixed &&
              convertToThousandDigit(balance, currentCurrency?.precision)}
          </p>

          <CommonAssetNetworkList
            className={`${style.withdraw}`}
            cbFun={cbFun}
            balanceObj={balanceObjState}
            type={sendReceiveType.withdraw}
            protocol={protocol}
            setStates={setStates}
            setCheckNetworkErr={setCheckNetworkErr}
            checkNetworkErr={checkNetworkErr}
          />
          <div className="networkErr">
            <p></p>
            <p className={`errorCls ${style.errorText}`}>{errors.network}</p>
          </div>

          <Tabs
            className="withdrawTab1"
            defaultActiveKey={coinHubUser}
            onChange={(value) => {
              setWithdrawData({
                ...withdrawData,
                email: "",
                address: "",
              });
              dispatch(setCoinHubUser(value));
              setPhone({ ...initPhone });
              setErrors({ ...errors, email: "", address: "" });
              setPhoneError("");
            }}
            activeKey={coinHubUser}
          >
            <TabPane
              tab={<p>{t("mining_pool.address")}</p>}
              key="withdrawalAddress"
            >
              <div className={style.withdrawalAddress}>
                <h6>{t("deposit.important")}</h6>
                {currentNetwork?.blockchain_key?.split("-")[0] && (
                  <p>
                    {t("send&Receive.note4", {
                      value: `${plusNum(
                        min_withdraw_amount,
                        withdraw_fee
                      )} ${currInCap}`,
                    })}
                  </p>
                )}
                {/* <p>
                {currentNetwork?.blockchain_key?.split("-")[0] && (
                  <p>
                    {t("send&Receive.note4", {
                      value: `${plusNum(
                        min_withdraw_amount,
                        withdraw_fee
                      )} ${currInCap}`,
                    })}
                  </p>
                )}
                <p>
                  {t("send&Receive.note9", {
                    value: `${plusNum(
                      min_withdraw_amount,
                      withdraw_fee
                    )} ${currInCap}`,
                  })}
                </p> */}
                <p>
                  <Trans i18nKey={"send&Receive.note5"}>
                    <span />
                  </Trans>
                </p>
                <p>{t("send&Receive.note6")}</p>
                <p className="grey twelve">
                  <b>{t("depositImp")} </b>
                </p>
                <div className={style.withdrawalAddress_inputSec}>
                  <div className={style.addressbookIcon}>
                    <img
                      src={AddressIcon}
                      alt="Address Icon"
                      width={19}
                      height={19}
                      onClick={() => {
                        if (currentNetwork?.blockchain_key?.split("-")[0]) {
                          setAddressBookModal(true);
                          setStates(false);
                        } else {
                          message.error(t(`withdraw.networkError`));
                        }
                      }}
                    />
                  </div>
                  <Custom_input
                    label={`${currInCap} ${t("deposit.history_address")}`}
                    placeholder={t("deposit.history_address")}
                    name="address"
                    value={withdrawData.address}
                    // value={withdrawData.address ? withdrawData.address :
                    //   filter[0]?.data?.address}
                    onChange={handleChange}
                    max={100}
                    height={"50px"}
                    customClass={"customInputWithdarw"}
                    autocomplete="off"
                  />
                  <span
                    className={`errorCls ${style.bottomO} ${style.errorTextInput}`}
                  >
                    {errors.address}
                  </span>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={<p>{t("mining_pool.coinhubUser")}</p>}
              key="coinHubUser"
            >
              <Tabs
                className="withdrawTab2"
                defaultActiveKey="1"
                onChange={(value) => {
                  setSelectedPane2(value);
                  setWithdrawData({ ...withdrawData, email: "" });
                  setPhone({ ...initPhone });
                  setErrors({ ...initData });
                  setPhoneError(null);
                }}
                activeKey={selectedPane2}
              >
                <TabPane tab={<p>{t("rewardRefHis.email")}</p>} key="email">
                  <Custom_input
                    name="email"
                    value={withdrawData.email}
                    placeholder={t("signup.email")}
                    onChange={handleChange}
                    label={t("withdraw_fiat.enter_Your_Email")}
                  />
                  {errors.email && (
                    <p
                      className={`errorCls ${style.bottomO} ${style.errorTextInput}`}
                    >
                      {errors.email}
                    </p>
                  )}
                </TabPane>
                <TabPane tab={<p>{t("phone_tab.phone_step")}</p>} key="phone">
                  <CustomPhoneNoInput
                    enableSearch
                    value={phone}
                    label={
                      <span className={style.labelTextSpan}>
                        {t("signup.phon_number")}
                      </span>
                    }
                    onChange={(obj) => {
                      if (firstClick) {
                        const res = customPhoneValidation(obj);
                        setPhoneError(res.error);
                      }
                      setPhone(obj);
                    }}
                  />
                  <span className="errorCls">{t(phoneError)}</span>
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
          <div
            className={`${style.withdrawalAddress_inputSec} ${style.lastChild}`}
          >
            {/* {!is2FA ? ( */}
            <>
              <div
                className={`${style.input_righttext} input_righttext passPadding `}
              >
                <label style={{ marginBottom: "10px", display: "block" }}>
                  {t("withdraw_fiat.enter_Email_OTP")}
                </label>
                <Custom_input
                  name="code"
                  value={withdrawData.code}
                  placeholder={t("withdraw_fiat.enter_Code")}
                  onChange={handleChange}
                  onlycurrency={`${
                    otpClicked
                      ? seconds
                        ? `${seconds} Seconds`
                        : `Resend OTP`
                      : `${t("addrsManagement.getCode")}`
                  }`}
                  rightTextClass={resendStatus && !seconds ? "otp_clicked" : ""}
                  {...(!resendStatus && {
                    rightTextClick: () => {
                      sendOtp();
                    },
                  })}
                />

                {/* <CustomError errors={errors} name="otp" /> */}
              </div>

              <span className={`errorCls`}>{errors.code}</span>
            </>
            <Custom_input
              label={t("addrsManagement.2fa")}
              placeholder={t("addrsManagement.2fa")}
              name="otp"
              value={withdrawData.otp}
              onChange={handleChange}
              max={6}
              height={"50px"}
              customClass={"customInputWithdarw"}
              disable={is2FA ? false : true}
            />
            <span className={`errorCls`}>{errors.otp}</span>
            {/* ) : ( */}
            {!is2FA && (
              <div className="btn2FaEnable">
                <CommonButton
                  title={t("BtnText.2fa")}
                  onClick={() => setEnable2fa(true)}
                />
              </div>
            )}

            {/* )} */}
          </div>
          {coinHubUser === "withdrawalAddress" && (
            <>
              <p>
                {t("withdraw.fee")}:{" "}
                <span>
                  {isInternalAddress === false ||
                  isInternalAddress === undefined
                    ? withdraw_fee
                    : internal_fee}{" "}
                  {currInCap}
                </span>
              </p>
              <p>
                {t("withdraw.will_get")}:<span> {getAmount}</span>
              </p>
            </>
          )}
          <div className={style.Buy_btnMain}>
            {/* <CommonButton
              title={t("buttons.send")}
              className="btn"
              onClick={onSubmit}
              disabled={disbaleBtn}
              btnType="submit"
            /> */}

            <CommonButton
              title={t("buttons.Sendkyc")}
              className="btn"
              // onClick={showModal}
              // disabled={disbaleBtn}
              btnType="submit"
            />
          </div>
        </form>
      </div>

      {addressBookModal && (
        <AddressBook
          addressBook={addressBookModal}
          onCancel={() => setAddressBookModal(false)}
          closeModalIfOnSameRoute={closeModalIfOnSameRoute}
          cbFun={(text, isInternal, protocol) => {
            setProtocol(protocol);
            handleValidation({ address: text });
            setWithdrawData({ ...withdrawData, address: text });
            setIsInternalAddress(isInternal);
          }}
          currCurrency={currency}
          currNetwork={currentNetwork?.blockchain_key?.split("-")[0]}
        />
      )}
      {enable2fa && (
        <GoogleAuthModal
          open={enable2fa}
          onCancel={() => setEnable2fa(false)}
        />
      )}

      {cmnModalState.isSuccOpen && (
        <Modal
          className="modlcust"
          open={cmnModalState.isSuccOpen}
          onOk={handleCancelsuc}
          onCancel={handleCancelsuc}
        >
          <div className="modalwith">
            <div className="modalwith_innrwith succes">
              <img src={check} />
              <h3>Withdrawal Request submitted</h3>
              <p className="cent_text mb-20">
                Please Note that you will receive an email once it is completed
              </p>

              <span className="cent_text">the Receiver will get </span>
              <h3>
                {cmnModalState.sendData.getAmount !== 0
                  ? cmnModalState.sendData.getAmount
                  : cmnModalState.sendData.amount}{" "}
                <span>{uppercase(currentCurrency?.name)}</span>
              </h3>
              <div className="ullist spacetop">
                <ul>
                  {coinHubUser === "withdrawalAddress" ? (
                    <>
                      <li>
                        <p>Address</p>
                        <p>{withdrawData?.address || ""}</p>
                      </li>
                      <li>
                        <p>Network</p>
                        <p>
                          {convertNetworkName(
                            uppercase(
                              currentNetwork?.blockchain_key?.split("-")[0]
                            )
                          )}
                        </p>
                      </li>
                    </>
                  ) : coinHubUser === "coinHubUser" ? (
                    <>
                      <li>
                        <p>{withdrawData.email !== "" ? "Email" : "Phone"}</p>
                        <p>
                          {withdrawData?.email ? withdrawData?.email : phoneNo}
                        </p>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li>
                    <p>Currency</p>
                    <p>{uppercase(currentCurrency?.id)}</p>
                  </li>
                </ul>
              </div>
              <div className="widthconfirm">
                <button onClick={handleCancelsuc}>Complete</button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {cmnModalState.isWithOpen && (
        <Modal
          className="modlcust"
          open={cmnModalState.isWithOpen}
          onOk={hndmdlCancel}
          onCancel={hndmdlCancel}
        >
          <div className="modalwith">
            <div className="modalwith_innrwith">
              <h1>Withdrawal</h1>
              <div className="ullist">
                <ul>
                  {coinHubUser === "withdrawalAddress" ? (
                    <>
                      <li>
                        <p>Address</p>
                        <p>{withdrawData?.address || ""}</p>
                      </li>
                      <li>
                        <p>Network</p>
                        <p>
                          {convertNetworkName(
                            uppercase(
                              currentNetwork?.blockchain_key?.split("-")[0]
                            )
                          )}
                        </p>
                      </li>
                    </>
                  ) : coinHubUser === "coinHubUser" ? (
                    <>
                      <li>
                        <p>{withdrawData.email !== "" ? "Email" : "Phone"}</p>
                        <p>
                          {withdrawData?.email ? withdrawData?.email : phoneNo}
                        </p>
                      </li>
                    </>
                  ) : (
                    ""
                  )}

                  <li>
                    <p>Currency</p>
                    <p>{uppercase(currentCurrency?.id)}</p>
                  </li>
                </ul>
              </div>
              <ul className="bullets">
                <li>
                  Ensure that the address is correct and on the same network.
                </li>
                <li>Transaction cannot be cancelled.</li>
              </ul>
              <div className="widthconfirm">
                <button onClick={finalSubmit} disabled={disbaleBtn}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {cmnModalState.isModalOpen && (
        <Modal
          className="modlcust"
          open={cmnModalState.isModalOpen}
          onOk={handleCancel}
          onCancel={handleCancel}
        >
          <div className="modalcnf">
            <div className="modalcnf_innr">
              <img src={Roundedimg} />
              <p>
                The Network you selected in {uppercase(currentCurrency?.id)} ,
                please confirm that your withdrawal address supports the{" "}
                {convertNetworkName(
                  uppercase(currentNetwork?.blockchain_key?.split("-")[0])
                )}{" "}
                network . If the other platform does not support it ,your assets
                may be lost
              </p>
              <div>
                <button onClick={handleCancel}>Return</button>
                <button onClick={withdrwaModal}>Confirm</button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Send;
