import React, { useEffect, useState } from "react";
import style from "../Banks/style.module.scss";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import { Button, Row, Col, Tabs } from "antd";
import CryptoDepositeTab from "./CryptoDepositeTab";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Recived from "../../DepositWithdraw/Recived";
import TabPane from "antd/lib/tabs/TabPane";
import Send from "../../DepositWithdraw/Send";
import { filterCurrWithNetwork, lowercase } from "../../../../helpers";
import { getBalance, getCurrencies } from "../../../../redux/services";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { currentCurrency, emptyAddress, setCoinHubUser, setSelectedPaneTab } from "../../../../redux/feature";

const CryptoDesposite = () => {
  const { MNT, ArrowRightGreen } = StoreImages;
  const currencyId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const type = location.state?.type || "deposit";

  const { tab } = useSelector(
    (state) => state.sendReceive
  );
    const [selectedPane, setSelectedPane] = useState("deposite");
    const { currenciesList } = useSelector((state) => state?.commonApiData);
  const saveCurrentMarket = useSelector(
    (state) => state?.exchange?.saveCurrentMarket
  );

  useEffect(() => {
    setSelectedPane(tab)
  }, [tab])
  const [currentObj, setCurrentObj] = useState({});
  useEffect(() => {
    actionsFun();
  }, []);
  const filterCurrencyByType = (balList, currList) => {
    let filterArr = [];
    if (Object.keys(saveCurrentMarket).length) {
      // This block executes if saveCurrentMarket has keys
      filterArr = balList?.filter(
        (val) =>
          val.currency === saveCurrentMarket.id ||
          val.currency === saveCurrentMarket.currency
      );

      if (filterArr.length > 0 && filterArr[0].deposit_addresses.length > 0) {
        setCurrentObj(filterArr[0]);
        return filterArr; // Return the filtered array
      } else {
        // This block executes if saveCurrentMarket doesn't have keys
        filterArr = balList?.filter(
          (val, idx) => val?.currency_type === "coin"
        );
      }
    } else {
      // This block executes if saveCurrentMarket doesn't have keys
      filterArr = balList?.filter((val, idx) => val?.currency_type === "coin");
    }

    if (filterArr.length) {
      let newArray = filterCurrWithNetwork(currList, balList);
      let findCurr = newArray?.filter(
        (val, idx) => val?.currency === lowercase(currencyId.id)
      );
      if (currencyId.id) {
        setCurrentObj(findCurr[0]);
      } else {
        setCurrentObj({ ...newArray[0] });
      }
    }

    return filterArr; // Return the filtered array
  };

  const actionsFun = async () => {
    // onClose();
    let res = await dispatch(getBalance());
    let res1 = await dispatch(getCurrencies());
    if (res.payload.status && res1.payload.status) {
      filterCurrencyByType(res.payload.list, res1.payload.list);
    }
  };
  const bankpage = () => {
    // Use navigate to go to the About page
    navigate("/bank_deposit");
  };

  const QA = [
    {
      id: 1,
      Question: (
        <>
          <a
            href="https://support.coinhub.mn/hc/mn/articles/4434834196249--%D0%9A%D1%80%D0%B8%D0%BF%D1%82%D0%BE-%D0%BE%D1%80%D0%BB%D0%BE%D0%B3%D0%BE-%D1%85%D0%B8%D0%B9%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80"
          >
            {t("BankDeposit.fq.Q1")}
          </a>
        </>
      ),
      // Ans: <>{t("BankDeposit.fq.ans1")}</>,
    },
    {
      id: 2,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434853562137--%D0%9A%D1%80%D0%B8%D0%BF%D1%82%D0%BE-%D0%B7%D0%B0%D1%80%D0%BB%D0%B0%D0%B3%D0%B0-%D0%B3%D0%B0%D1%80%D0%B3%D0%B0%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">{t("BankDeposit.fq.Q2")}</a>{" "}
        </>
      ),
      // Ans: <>{t("BankDeposit.fq.ans2")}</>,
    },
    {
      id: 3,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434807953305--%D0%A2%D3%A8%D0%93%D0%A0%D3%A8%D0%93-%D0%B8%D0%B9%D0%BD-%D0%BE%D1%80%D0%BB%D0%BE%D0%B3%D0%BE-%D1%85%D0%B8%D0%B9%D1%85-%D1%85%D1%8D%D1%82%D1%8D%D0%B2%D1%87-%D1%86%D1%8D%D0%BD%D1%8D%D0%B3%D0%BB%D1%8D%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">{t("BankDeposit.fq.Q3")} </a>
        </>
      ),
      // Ans: <>{t("BankDeposit.fq.ans3")}</>,
    },
    {
      id: 4,
      Question: (
        <>
          <a href="https://support.coinhub.mn/hc/mn/articles/4434803881369--%D0%A2%D3%A8%D0%93%D0%A0%D3%A8%D0%93-%D0%B8%D0%B9%D0%BD-%D0%B7%D0%B0%D1%80%D0%BB%D0%B0%D0%B3%D0%B0-%D0%B3%D0%B0%D1%80%D0%B3%D0%B0%D1%85-%D0%B7%D0%B0%D0%B0%D0%B2%D0%B0%D1%80">{t("BankDeposit.fq.Q4")}</a>
        </>
      ),
      // Ans: <><a href=""></a></>,
    },
  ];
  const items = [
    {
      key: "1",
      label: "Deposit",
      children: <CryptoDepositeTab />,
    },
    {
      key: "2",
      label: "Withdraw",
      // children: <CryptoWithDrawTab />,
    },
  ];

  return (
    <>
      {/* <div className={style.bar}>
        <h4>
          <span>Currency</span>
          <img src={MNT} alt="icon" />
          MNT
        </h4>
      </div> */}
      <div className="comman-padding">
        <div className={style.banner}>
          <div className={style.banner_content}>
            {/* <h4>Crypto Deposit</h4> */}
            <h4>{t("Fund.subheadingtitle1")}</h4>
            <Button onClick={bankpage}>
              {t("Fund.subheadingtitle2")}
              <img
                style={{ padding: "6px" }}
                src={ArrowRightGreen}
                alt="icon"
              />
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "30px" }} className="dispositWithdrawTab">
          <Row gutter={[40, 40]}>
            <Col xl={12} xs={24} className="cmnTabStyle">
              <div className="bank-deposite-draw-tabs">
                <Tabs
                  className="open_AllOrderTab"
                  defaultActiveKey="1"
                  onChange={(value) => {
                    setSelectedPane(value);
                    dispatch(setSelectedPaneTab(value));
                    dispatch(emptyAddress());
                    dispatch(currentCurrency(""));
                    if (value === "deposit") {
                      dispatch(setCoinHubUser("withdrawalAddress"));
                    }
                  }}

                  activeKey={selectedPane}
                >

                  <TabPane
                    tab={<p>{t("history_account.deposits")}</p>}
                    key="deposit"
                  >
                    {selectedPane === "deposit" && (
                      <Recived balanceObj={currentObj} />
                    )}
                  </TabPane>
                  <TabPane
                    tab={<p>{t("history_account.withdraws")}</p>}
                    key="withdraw"
                  >
                    {selectedPane === "withdraw" && (
                      <Send
                        balanceObj={currentObj}
                      // closeModal={sendReceiveCloseModal}
                      />
                    )}
                  </TabPane>

                </Tabs>
              </div>
            </Col>
            <Col
              xl={12}
              xs={24}
              className="cmnTabStyle"
              style={{ display: "flex", height: "auto" }}
            >
              <div className={style.QApart}>
                <h4>{t("BankDeposit.fq.title")}</h4>
                {QA.map((text) => (
                  <div className={style.QApart_content} key={text.id}>
                    <h5>{text.Question}</h5>
                    <p>{text.Ans}</p>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CryptoDesposite;
